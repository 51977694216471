import React, { useEffect, useState } from "react";

import Screen from "./screen/Screen";
import config from "./config.json";
import { getToken, getMyInfo } from "./service/api";
import { createNotification, ToastType } from "./screen/components/Toast";

const addFavicon = (icon) => {
  let link = document.createElement("link");
  link.setAttribute("rel", "icon");
  link.setAttribute("href", icon);
  document.head.append(link);
}
let stores = [];
let messages = {
  successMessage: ""
};
const App = () => {

  const [appTheme, setAppTheme] = useState({});
  const [appLoading, setAppLoading] = useState(true);

  useEffect(() => {
    getToken().then(() =>
      getMyInfo().then((data) => {
        if (data) {
          document.title = data.mainStore?.company?.name;
          addFavicon(config.host_api + data.mainStore?.company?.customizer.faviconUrl);
          setAppTheme(data.mainStore?.company?.customizer);
          stores = data.stores;
          messages.successMessage = data.mainStore?.company?.customizer.successMessage;
        }
        setAppLoading(false);
      })
    ).catch(er => {
      createNotification("Error", "Ne peut pas rechercher!", ToastType.ERROR);
    });
  }, []);


  const css = `
    :root {
      --borderColor: ${appTheme.borderColor ?? "#FFF"};
      --buttonConfirmColor: ${appTheme.buttonConfirmColor ?? "#000"};
      --buttonNewColor: ${appTheme.buttonNewColor ?? "#000"};
      --titleColor: ${appTheme.titleColor ?? "#FFF"};
      --backgroundColor : ${appTheme.backgroundColor ?? "#000"};
      --underTextColor: ${appTheme.underTextColor ?? "#FFF"};
      --backgroundColorFooter : ${appTheme.backgroundColorFooter ?? "#000"};
      --primaryLogo: ${appTheme.imageUrl ? ("url(" + config.host_api + appTheme.imageUrl + ")") : "none"};
      --storeLogo: ${appTheme.logoUrl ? ("url(" + config.host_api + appTheme.logoUrl + ")") : "none"};
    }
  `;

  if (appLoading) {
   return <div className="App"></div>
  }

  return (
    <div className="App">
      <style>{css}</style>
      <Screen />
    </div>
  );
}

export { stores, messages };
export default App;
