import React, { useEffect, useRef, useState } from "react";

import { getQRcode } from "../../../service/api";
import Button from "../../components/Button";
import { createNotification, ToastType } from "../../components/Toast";

const BtnMode = {
    DISABLED: 0,
    ENABLED: 1,
    WAITING: 2,
}

const PricePart = ({ result = {}, next = () => { }, prev = () => { } }) => {
    const [email, setEmail] = useState("");
    const [qr, setQr] = useState("");
    const [btnDisable, setBtnDisable] = useState(BtnMode.DISABLED);

    useEffect(() => {
        if (checkMail()) {
            setBtnDisable(BtnMode.ENABLED);
        } else {
            setBtnDisable(BtnMode.DISABLED);
        }
    }, [email]);

    useEffect(() => {
        if (qr !== "") {
            window.scroll(0, window.screen.availHeight);
        } else {
            window.scroll(0, 0);
        }
    }, [qr]);


    const getQrCodeByOrder = async (data) => {
        const order = {
            brand: data.brand.name,
            category: data.category.name + "." + data.category.children.name,
            material: data.material.name,
            size: data.size.name,
            color: data.color.name,
            state: data.state.state,
            email: email,
        }
        let res = await getQRcode(order);
        if (res) {
            const qrCode = "data:image/jpeg;base64, " + res.qrcode;
            order.state = data.state.name;
            order.price = data.price;
            order.qrCode = qrCode;
        }
        return order;
    }

    const submit = () => {
        if (checkMail() && !!next) {
            setBtnDisable(BtnMode.WAITING);
            getQrCodeByOrder(result).then((order) => {
                setQr(order.qrCode);
            }).catch(e => {
                setBtnDisable(BtnMode.ENABLED);
                createNotification("", "Votre adresse mail n'est pas correct", ToastType.ERROR);
            });
        }
    }

    const checkMail = () => {
        const arr = email.match(/\S+@\S+\.\S+/);
        if (arr && arr[0].length === email.length) {
            return true;
        }
        return false;
    }

    return (
        <div className="pricePart bgImage bgImage-right">
            <div className="title"> VOTRE RECAPITULATIF</div>
            <div className="filterBox d-flex flex-row align-self-center justify-content-around flex-wrap">
                <div className="filter">
                    <div className="name">Marque</div>
                    <div className="value">{result.brand.name}</div>
                </div>
                <div className="filter">
                    <div className="name">Catégorie</div>
                    <div className="value">{result.category.name + " " + result.category.children.name}</div>
                </div>
                <div className="filter">
                    <div className="name">Matière principale</div>
                    <div className="value">{result.material.name}</div>
                </div>
                <div className="filter">
                    <div className="name">Couleur</div>
                    <div className="value">{result.color.name}</div>
                </div>
                <div className="filter">
                    <div className="name">Taille</div>
                    <div className="value">{result.size.name}</div>
                </div>
                <div className="filter">
                    <div className="name">Etat</div>
                    <div className="value">{result.state.name}</div>
                </div>
            </div>
            <div className="message">Estimation de votre bon d’achat* :</div>
            <div className="price">{result.price ?? 0} €</div>
            <div className="message-mini">* sous réserve du contrôle qui sera fait en boutique </div>
            {qr === "" &&
                <input className="input_mail form-control"
                    placeholder="hello@gmail.fr"
                    value={email}
                    onChange={({ target: { value } }) => setEmail(value)} />
            }
            {qr !== "" &&
                <div className="qrCodeview">
                    <img src={qr} height="100%" alt="" />
                </div>
            }
            <div className="btnGroup d-flex flex-wrap justify-content-between">
                {qr === "" &&
                    <Button className="btn-green"
                        title={btnDisable === BtnMode.WAITING ?
                            "Attendre..." : "Recevoir mon QRCODE"}
                        disabled={btnDisable !== BtnMode.ENABLED}
                        onClick={submit} />
                }
                <Button className="btn-blue"
                    title="Je recommence"
                    onClick={prev} />
            </div>
        </div>
    )
}

export default PricePart;