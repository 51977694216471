import "../assets/css/screen-full.css";
import "../assets/css/screen-normal.css";
import "../assets/css/screen-mobile.css";
import "../assets/css/toast.css";

import React, { useEffect, useState } from 'react';

import { getToken, getDataFilter } from "../service/api";
import FilterPart from "./parts/filterPart";
import PricePart from "./parts/pricePart";
import ResultFailPart from "./parts/resultFailPart";
import Toast from "./components/Toast";

const Screen = () => {
    const [indexView, setIndexView] = useState(0);
    const [result, setResult] = useState({});
    const [originData, setOriginData] = useState({
        newBrands: [], newCategories: [], newMaterials: [], newColors: [], newSizes: [], newStatus: []
    });

    useEffect(async () => {
        const [newBrands, newMaterials, newColors, newStatus] = await getDataFilter();

        setOriginData({
            newBrands: newBrands["hydra:member"],
            newMaterials: newMaterials["hydra:member"].map(val => ({ ...val, name: val.material })),
            newColors: newColors["hydra:member"].map(val => ({ ...val, name: val.color })),
            newStatus: newStatus["hydra:member"],
        });
    }, []);

    const scriptView = [
        <>
            <FilterPart originData={originData}
                next={(rs) => {
                    setResult(rs);
                    setIndexView(1);
                }} fail={() => {
                    console.log("errr");
                    setIndexView(2);
                }} />
        </>, // 0
        <>
            <PricePart
                result={result}
                next={() => {
                }}
                prev={() => setIndexView(0)}
            />
        </>, // 1
        <>
            <ResultFailPart next={() => {
                window.scrollTo(0, 0);
                setIndexView(0);
                setResult({});
            }} />
        </>, // 2
    ];

    return (
        <div className="homePart">
            <Toast />
            {scriptView[indexView]}
        </div>
    )
}

export default Screen;