import axios from "axios";
import decode from "jwt-decode";

import config from "../config.json";
import { createNotification, ToastType } from "../screen/components/Toast";

const API = axios.create({
    baseURL: config.host_api,
    timeout: 30000
});

let token = undefined;

API.interceptors.request.use(async (req) => {
    if (token) {
        req.headers["Authorization"] = "Bearer " + token;
    }
    req.headers["Content-Type"] = "application/json";
    return req;
});

API.interceptors.response.use((res) => {
    return res.data;
}, (err) => {
    throw err;
});

const getToken = async () => {
    try {
        const { data } = await axios({
            baseURL: config.host_api,
            url: "authentication",
            method: "post",
            data: {
                email: config.email,
                password: config.password
            }
        });
        if (data) {
            token = data.token;
            const { exp } = decode(token);
            const expires = exp * 1000 - Date.now();
            setTimeout(getToken, expires);
        }
    } catch (e) {
        createNotification("Erreur", "Impossible de se connecter au serveur", ToastType.ERROR);
    }
}

const getDataFilter = async () => {
    const data = await Promise.all([
        API.get("brands?itemsPerPage=0"),
        API.get("materials"),
        API.get("colors"),
        API.get("states"),
    ]);
    return data;
}

const getSizesByCategory = (categoryId) => {
    return API.get("sizes", {
        params: {
            category: categoryId
        }
    });
}
const getMyInfo = () => {
    return API.get("users/me");
}

const searchBrandByName = (text) => {
    return API.get("brands", {
        params: {
            name: text
        }
    });
}

const estimate = (param) => {
    return API.get("arguses", {
        params: param
    });
}

const getQRcode = (data) => {
    return API.post("argus/generate", data);
}

export {
    getDataFilter,
    getSizesByCategory,
    searchBrandByName,
    estimate,
    getQRcode,
    getToken,
    getMyInfo
}
